import Menu from './menu';
import Footer from './footer';
import Position from './position';
import ScrollToTop from './utils';
import { useTranslation } from 'react-i18next';


function Careers(props) {
    const { t, i18n } = useTranslation();

    const links = {
        en:"https://www.notion.so/marsauto/MARS-AUTO-IS-WHERE-THE-BEST-BELONG-2ddaf03ab3a14d258fa32a5507e5d337?pvs=4",
        ko:"https://www.notion.so/marsauto/MARS-AUTO-IS-WHERE-THE-BEST-BELONG-2ddaf03ab3a14d258fa32a5507e5d337?pvs=4"
    };

    const currentLanguage = i18n.language;
    const link = links[currentLanguage] || links.en; // Fallback to English if no match
    const responsibilities1 = t('careers_responsibilities1', { returnObjects: true });
    const qualifications1 = t('careers_qualifications1', { returnObjects: true });
    const preferred1 = t('careers_preferred1', { returnObjects: true });
    const responsibilities2 = t('careers_responsibilities2', { returnObjects: true });
    const qualifications2 = t('careers_qualifications2', { returnObjects: true });
    const preferred2 = t('careers_preferred2', { returnObjects: true });
    const responsibilities3 = t('careers_responsibilities3', { returnObjects: true });
    const qualifications3 = t('careers_qualifications3', { returnObjects: true });
    const responsibilities4 = t('careers_responsibilities4', { returnObjects: true });
    const qualifications4 = t('careers_qualifications4', { returnObjects: true });
    const preferred4 = t('careers_preferred4', { returnObjects: true });
    const responsibilities5 = t('careers_responsibilities5', { returnObjects: true });
    const qualifications5 = t('careers_qualifications5', { returnObjects: true });
    const preferred5 = t('careers_preferred5', { returnObjects: true });

    return (
        <div>
            <ScrollToTop />
            <Menu />
            <section class="py-6 py-lg-7" id="positions">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 mx-auto text-center">
                            <h2 class="text-white heading-black">{t('careers_title1')}<br/>{t('careers_title2')}</h2>
                        </div>
                    </div>
                    <div class="col-10 col-md-8 mx-auto mt-3">
                        <p class="lead mb-3">{t('careers_text1')} <a href="https://blog.marsauto.com/self-driving-2-0-d846625bb327" target="_blank">{t('careers_text2')}</a> {t('careers_text3')}</p>
                    </div>
                    <div class="text-center">
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <button class="btn btn-primary mt-2 row justify-content-center white-text">
                                {t('careers_button1')}
                            </button>
                        </a>
                    </div>
                    <div class="mx-auto text-center mt-5">
                        <h2 class="text-white heading-black" style={{ fontSize: "2.3rem" }}>{t('careers_title3')}</h2>
                    </div>
                    <Position
                        title={t('careers_position1')}
                        location={t('careers_location1')}
                        responsibilities={responsibilities1}
                        qualifications={qualifications1}
                        preferred={preferred1}>
                    </Position>
                    <Position
                        title={t('careers_position2')}
                        location={t('careers_location2')}
                        responsibilities={responsibilities2}
                        qualifications={qualifications2}
                        preferred={preferred2}>
                    </Position>
                    <Position
                        title={t('careers_position4')}
                        location={t('careers_location4')}
                        responsibilities={responsibilities4}
                        qualifications={qualifications4}
                        preferred={preferred4}>
                    </Position>
                    <Position
                        title={t('careers_position5')}
                        location={t('careers_location5')}
                        responsibilities={responsibilities5}
                        qualifications={qualifications5}
                        preferred={preferred5}>
                    </Position>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Careers;
