import { useTranslation } from 'react-i18next';

function Position(props) {
    const { t } = useTranslation();
    var preferred;
    if (props.preferred) {
        preferred =
            <div>
                <h4 class="card-title mb-1">{t('position_title1')}</h4>
                <ul class="list-inline-item pl-3">
                    {props.preferred.map((r) => <li>{r}</li>)}
                </ul>
            </div>
    }
    else {
        preferred = <div></div>
    }

    return (
        <div class="row pt-3 pb-5">
            <div class="col-12 mx-auto">
                <div class="card-deck">
                    <div class="card pl-4">
                        <div class="card-body">
                            <h2 class="card-title pt-3 mb-0">{props.title}</h2>
                            <div class="lead">{props.location}</div>
                            <hr class="" />
                            <h4 class="card-title pt-3 mb-1">{t('position_title2')}</h4>
                            <ul class="list-inline-item pl-3">
                                {props.responsibilities.map((r) => <li>{r}</li>)}
                            </ul>
                            <h4 class="card-title mb-1">{t('position_title3')}</h4>
                            <ul class="list-inline-item pl-3">
                                {props.qualifications.map((r) => <li>{r}</li>)}
                            </ul>
                            {preferred}
                            {props.requirements && props.requirements.length > 0 &&
                                <>
                                    <h4 class="card-title mb-1">{t('position_title4')}</h4>
                                    <ul class="list-inline-item pl-3">
                                        {props.requirements.map((r) => <li>{r}</li>)}
                                    </ul>
                                </>
                            }
                            <div>
                                <a href="mailto:jobs@marsauto.com" class="btn btn-primary pull-right mb-3 white-text">
                                    {t('position_button1')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Position;
