import video from './static/technology.mp4';
import Menu from './menu';
import { Articles } from './articles';
import DrivingScenes from './drivingScenes';
import Footer from './footer';
import ScrollToTop from './utils';
import { LeftParagraphWithPhoto, RightParagraphWithPhoto } from './paragraphWithPhoto';
import { useTranslation } from 'react-i18next';

import truck2 from './static/truck02.webp';
import truck3 from './static/truck03.webp';
import truck4 from './static/truck04.webp';
import img1 from './static/blog/blog1.webp';
import img2 from './static/blog/blog2.webp';
import img3 from './static/blog/blog3.webp';


function Technology() {
    const { t } = useTranslation();
    return (
        <div>
            <ScrollToTop />
            <Menu />
            <header class="bg-hero">
                <video autoPlay playsInline loop muted src={video} type="video/mp4" />
                <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center col-12">
                        <h1 id="company-title" class="heading-black">
                            {t('technology_title1')}
                        </h1>
                    </div>
                </div>
            </header>
            <RightParagraphWithPhoto
                photo={truck4}
                title={t('technology_title2')}
                text={t('technology_text1')}
                left="col-10 col-lg-7 order-2 order-lg-1"
                right="col-10 col-lg-5 order-1 order-lg-2"
            />
            <LeftParagraphWithPhoto
                photo={truck2}
                title={t('technology_title3')}
                text={t('technology_text2')}
                left="col-10 col-lg-6"
                right="col-10 col-lg-6"
            />
            <RightParagraphWithPhoto
                photo={truck3}
                title={t('technology_title4')}
                text={t('technology_text3')}
                left="col-10 col-lg-7 order-2 order-lg-1"
                right="col-10 col-lg-5 order-1 order-lg-2"
            />
            <div class="container mb-6 mb-lg-7">
                <h2 class="mt-5 mt-lg-6 font-weight-medium text-center">{t('technology_title5')}</h2>
                <p class="lead text-center">
                    {t('technology_text4')}
                </p>
                <div class="row justify-content-center">
                    <div class="embed-responsive embed-responsive-16by9 col-10">
                        <iframe title="marsauto youtube" class="embed-responsive-item" src="https://www.youtube.com/embed/TCX1Owlw0IY" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <DrivingScenes />
            {/* <Articles
                title="EXPLORE OUR BLOG"
                content={[
                    {
                        title: "Automated large scale data generation for autonomous vehicle",
                        text: "Over one year ago we built our pure vision neural network-based autonomous truck system to drive from Seoul to Busan fully autonomously...",
                        img: img1,
                        link: "https://medium.com/mars-auto/automated-large-scale-data-generation-for-autonomous-vehicle-59de8b26357e",
                        footer: "Dec 8, 2020",
                    },
                    {
                        title: "Marking an important milestone at Mars Auto",
                        text: "One year ago in November 2019, our team gathered together for an all hands meeting. Although we have such meetings twice a week, this one was different...",
                        img: img2,
                        link: "https://medium.com/mars-auto/marking-an-important-milestone-at-mars-auto-a864a77a668d",
                        footer: "Dec 7, 2020",
                    },
                    {
                        title: "Introducing the Mars Challenge",
                        text: "At Mars Auto, we look for exceptional engineers. Traditional white board interview is designed to hire a diligent engineer. If smart enough people prepare...",
                        img: img3,
                        link: "https://medium.com/mars-auto/introducing-the-mars-challenge-fc84c5afafbe",
                        footer: "Feb 23, 2020",
                    }
                ]}
            /> */}
            <Footer />
        </div >
    );
}

export default Technology;
