import Menu from './menu';
import Footer from './footer';
import ScrollToTop from './utils';

import video from './static/business.mp4';

function Business(props) {
    return (
        <div>
            <ScrollToTop />
            <Menu />
            <div class="py-7 py-md-0 bg-hero">
                <video autoPlay playsInline loop muted src={video} id="highway-video" type="video/mp4" />
                <div class="container">
                    <div class="row vh-md-100">
                        <div class="col-md-12 col-sm-12 col-12 mx-auto my-auto text-center">
                            <h1 id="company-title" class="heading-black">
                                BETTER FOR DRIVERS, BETTER FOR BUSINESS
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="mb-5">
                    <h2 class="mt-6 font-weight-medium text-center">
                        Contact
                    </h2>
                    <p class="text-muted lead text-center">
                        For inquiries regarding business partnerships, warehouse to warehouse shipping, or Mars Auto autonomous solutions, please contact us at <a
                            target="_blank" href="mailto:contact@marsauto.com">business@marsauto.com</a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Business;
