import logo from './static/logo_white.webp';

function DrivingScenes() {
    return (
        <div class="container">
            <div class="row"></div>
        </div>
    );
}

export default DrivingScenes;
