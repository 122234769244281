function LeftParagraphWithPhoto(props) {
    var button;
    if (props.showButton) {
        button =
            <a href={props.dst}>
                <button class="btn btn-primary d-inline-flex flex-row align-items-center mt-2 white-text">
                    {props.button}
                </button>
            </a>;
    } else {
        button = <div></div>;
    }
    return (
        <div class="container mt-5 mt-lg-6">
            <div class="row d-flex justify-content-center  align-items-center">
                <div class={props.left}>
                    <h2 class="font-weight-medium text-center">{props.title}</h2>
                    <p class="lead text-left">
                        {props.text}
                    </p>
                    {button}
                </div>
                <div class={props.right}>
                    <div class="justify-content-center">
                        <img src={props.photo} class="img-fluid col-12" />
                    </div>
                </div>
            </div>
        </div>
    );
}

function RightParagraphWithPhoto(props) {
    var button;
    if (props.showButton) {
        button =
            <a href={props.dst}>
                <button class="btn btn-primary row justify-content-center mt-2 mb-3 white-text">
                    {props.button}
                </button>
            </a>;
    } else {
        button = <div></div>;
    }

    return (
        <div class="container mt-5 mt-lg-6">
            <div class="row d-flex justify-content-center align-items-center">
                <div class={props.left}>
                    <div class="justify-content-center">
                        <img src={props.photo} class="img-fluid col-12" />
                    </div>
                </div >
                <div class={props.right}>
                    <h2 class="font-weight-medium text-center" >{props.title}</h2>
                    <p class="lead text-left">
                        {props.text}
                    </p>
                    {button}
                </div>
            </div >
        </div >
    );
}

export { LeftParagraphWithPhoto, RightParagraphWithPhoto };
