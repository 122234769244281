import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Nav } from 'react-bootstrap'; 

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <Dropdown as={Nav.Item} align="end" className="language-selector">
      <Dropdown.Toggle as={Nav.Link} className="text-white">
        {i18n.language.toUpperCase()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('ko')}>한국어</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
