import { Link } from "react-router-dom";

function MenuItem(props) {
    return (
        <div class="nav-item">
            <Link class="nav-link page-scroll" to={props.href}>{props.title}</Link>
        </div>
    );
}

function MenuLinkItem(props) {
    return (
        <div class="nav-item">
            <a class="nav-link page-scroll" href={props.href} target="_blank">{props.title} <i class="fa-xs fa-solid fa-up-right-from-square"></i></a>
        </div>
    );
}

export { MenuItem, MenuLinkItem };
