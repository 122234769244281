
export const fetchData = async (API_URL) => {
  const reqOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(API_URL, reqOptions);
  const data = await response.json();
  const statusCode = data.statusCode;

  if (statusCode === "10000") {
    return data.data;
  }

  if (statusCode === "10001") {
    throw new Error("Unauthorized");
  }

  throw new Error("Unknown error statusCode:" + statusCode);
};
