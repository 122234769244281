import yc from './static/img/yc.webp';
import kakao from './static/img/kakao.webp';
import mirae from './static/img/mirae.webp';
import zv from './static/img/zv.webp';
import gft from './static/img/gft.webp';
import { useTranslation } from 'react-i18next';

function Investors(props) {
    const { t } = useTranslation();
    return (
        <div class="mt-6 mb-6">
            <div class="col-10 mx-auto">
                <h2 class="heading-black text-center">{t('investors_title1')}</h2>
                <p class="lead text-center">
                    {t('investors_text1')}
                </p>
                <div class="col-md-12">
                    <div id="investors-list" class="d-flex flex-wrap justify-content-center">
                        <img id="mirae-logo" style={{ top: "-2.3px" }} src={mirae} class="photo position-relative"
                            alt="Mirae Asset" />
                        <img id="yc-logo" style={{ top: "1.1px" }} src={yc} class="photo position-relative"
                            alt="YC" />
                        <img id="gft-logo" style={{ top: "2px" }} src={gft} class="photo position-relative"
                            alt="GFT Ventures" />
                        <img id="kakao-logo" style={{ top: "1.7px" }} src={kakao} class="photo position-relative"
                            alt="KV" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Investors;
