function PressDate(props) {
    return (
        <div class="container pb-3">
            <div class="row">
                <div class="datebox text-center">
                    <div class="row d-flex">
                        <h6 class="text-muted mb-0">{props.month}</h6>
                        <h3 class="font-size-12 mb-0">{props.day}</h3>
                    </div>
                </div>
                <div class="col-1" />
                <div class="col-2 d-flex align-self-center">
                    <img class="col-12" src={props.source}></img>
                </div>
                <div class="col-8 d-flex align-self-center">
                    <a target="_blank" href={props.link}>{props.headline}</a>
                </div>
            </div>
        </div>
    );
}

export default PressDate;
