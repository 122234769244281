import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./translation/en.json'),
      },
      ko: {
        translation: require('./translation/ko.json'),
      },
    },
    fallbackLng: 'en', // Use 'en' if no other language is detected
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    // detection: {
    //   order: ['localStorage', 'navigator'], // Look in localStorage, then navigator
    //   caches: ['localStorage'], // Cache the language in localStorage
    //   lookupLocalStorage: 'i18nextLng', // Specify the localStorage key
    //   // Other detection plugin options can be added here
    // },
    backend: {
      loadPath: '/translation/{{lng}}.json', // Where to find the translation files
    },
  });

export default i18n;
