import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Menu from "./menu";
import { fetchData } from "./tmap/fetch";
import Footer from "./footer";
import TmapWindow from "./tmap/TmapWindow";
import ScrollToTop from "./utils";
import "./fms.css";

const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });
const outOfBounds = (lat, lon) => {
  // fix between 34-39 and 125 - 130 (lat and lon bounds of Korea on the map)
  const latMin = 33;
  const latMax = 38.65;
  const lonMin = 124.8;
  const lonMax = 129.64;
  if (lat < latMin || lat > latMax || lon < lonMin || lon > lonMax) {
    return true;
  }
  return false;
};

// dev env:
// const MARSBOX_HOST = "http://localhost:3033/api/v1/marsauto/recent"

// prod env:
const MARSBOX_HOST = "https://manager.marsauto.com/api/v1/marsauto/recent";
const TMAP_UPDATE_TIME = 3000; // 10 seconds

function FMS(props) {
  const { t, i18n } = useTranslation();

  const tmapDivID = "TmapAPP";
  const tmapWindowRef = useRef(null);
  const persistMarsboxListRef = useRef({});
  const [myMarsboxList, setMyMarsboxList] = useState({});

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (tmapWindowRef.current) {
      tmapWindowRef.current.fitTmap2(screenSize);
    }
  }, [screenSize]);

  useEffect(() => {
    getData(true);
    const interval = setInterval(() => getData(), TMAP_UPDATE_TIME);
    return () => clearInterval(interval);
  }, []);

  const getData = async (initial = false) => {
    let marsboxes = {};

    try {
      const data = await fetchData(MARSBOX_HOST);
      marsboxes = data.devices;
    } catch (e) {
      marsboxes = {};
      console.error(e);
    }

    Object.entries(marsboxes).forEach(([key, value]) => {
      marsboxes[key].prev_lat = tmapWindowRef.current.marsboxData[key]?.lat || null;
      marsboxes[key].prev_lon = tmapWindowRef.current.marsboxData[key]?.lon || null;
    })

    if (tmapWindowRef.current != null) {
      tmapWindowRef.current.setMarsboxData(marsboxes);
    }

    updateMarsboxData(marsboxes);
  };

  const updateMarsboxData = (newMarsboxList) => {
    const persistMarsboxList = persistMarsboxListRef.current;

    for (const [deviceName, newMarsboxData] of Object.entries(newMarsboxList)) {
      if (persistMarsboxList[deviceName]) {
        const currMarsboxData = persistMarsboxList[deviceName];
        persistMarsboxList[deviceName] =
          newMarsboxData.lat &&
            newMarsboxData.lon &&
            !outOfBounds(newMarsboxData.lat, newMarsboxData.lon) &&
            currMarsboxData.last_ping_time < newMarsboxData.last_ping_time
            ? newMarsboxData
            : currMarsboxData;
      } else {
        persistMarsboxList[deviceName] = newMarsboxData;
      }
    }

    const now = new Date().getTime() / 1000;
    const thresholdSeconds = 3 * 60;
    for (const marsboxData of Object.values(persistMarsboxList)) {
      if (now - marsboxData.last_ping_time < thresholdSeconds) {
        if (!marsboxData.active) marsboxData.active = true;
      } else {
        if (marsboxData.active) marsboxData.active = false;
      }
    }

    // sort by device name
    const sortedNextMarsboxList = Object.keys(persistMarsboxList)
      .sort(sortAlphaNum)
      .reduce((newObj, key) => {
        newObj[key] = persistMarsboxList[key];
        return newObj;
      }, {});

    setMyMarsboxList(sortedNextMarsboxList);
    if (tmapWindowRef.current != null) {
      tmapWindowRef.current.setMarsboxData(sortedNextMarsboxList);
    }
  };

  return (
    <div>
      <ScrollToTop />
      <Menu />
      <section class="py-6 py-lg-7">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mx-auto text-center">
              <h2 class="text-white heading-black">{t('fms_title1')}</h2>
            </div>
            <div class="col-10 col-md-8 mx-auto text-center">
              <h3 class="text-white heading-black">
                {t('fms_text1')}<br />
                {t('fms_text2')}<br />
                <a href="mailto:box@marsauto.com">box@marsauto.com</a>
              </h3>
            </div>
          </div>
          <h2 class="col-md-6 mx-auto text-center text-white heading-black">{ }</h2>
          <h3 class="col-md-8 mx-auto text-center text-white heading-black">{ }</h3>
          <hr class="no-top-margin" />
          <div class="row mx-auto" style={{ position: "relative" }}>
            <TmapWindow
              divID={tmapDivID}
              showAutoFitMapBounds={true}
              single={false}
              ref={tmapWindowRef}
            />
            <div
              class="row mx-auto map-container"
              style={{
                position: "absolute",
                top: 0,
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
            ></div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default FMS;
