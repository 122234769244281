import './App.css';
import Menu from './menu';
import About from './about';
import Investors from './investors';
import Footer from './footer';
import ScrollToTop from './utils';
import { useEffect, useRef, useState } from 'react';
import './i18n';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const tnames = {
  kimchi1: "K1",
  kimchi4: "K4",
}

function App() {
  const { t } = useTranslation();
  const [onlineTruck, setOnlineTruck] = useState(null)
  const [loadIsStreaming, setLoadIsStreaming] = useState(false)

  const videoUrl = "https://marsauto-website.s3.ap-northeast-2.amazonaws.com/website_video.mp4"

  return (
    <Suspense fallback="loading">
      <div className="App">
        <ScrollToTop />
        <Menu />

      <header>
        <video autoPlay muted playsInline loop id="autonomous-driving-video" className="auto-video">
          <source src={videoUrl} type="video/mp4" />
        </video>
      </header>

        <section class="pt-5 pt-lg-6 pb-5 pb-lg-6" id="about">
          <div>
            <About />
            <div class="text-center">
              <a href="/careers">
                <button class="btn btn-primary d-inline-flex flex-row align-items-center mt-5 white-text">
                  {t('app_button1')}
                </button>
              </a>
            </div>
            <Investors />
          </div>
        </section >
        <Footer />
      </div >
    </Suspense>
  );
}


export default App;
