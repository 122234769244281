import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import App from './App';
import Business from './business';
import Careers from './careers';
import PressOld from './press';
import Press from './pressNew';
import Technology from './technology';
import FMS from './fms';

import 'bootstrap/dist/css/bootstrap.css';
import './blue.css';
import './index.css';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="technology" element={<Technology />} />
        <Route path="business" element={<Business />} />
        <Route path="careers" element={<Careers />} />
        <Route path="press" element={<Press />} />
        <Route path="pressOld" element={<PressOld />} />
        <Route path="fms" element={<FMS/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
