import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";

import styles from "./TmapWindow.module.css";
import "./TmapMarker.css";
import blueMarker from "../static/img/tmap/pin_car_blue.png";
import marsMarker from "../static/img/tmap/marsauto.png";

const MARKER_INACTIVE_Z_INDEX = 1;
const MARKER_ACTIVE_Z_INDEX = 10;
const MARKER_HIGHLIGHT_Z_INDEX = 100;

const MARKER_INACTIVE_CLASS = "inactive";
const MARKER_ACTIVE_CLASS = "active";
const MARKER_HIGHLIGHT_CLASS = "highlight";

const MARSAUTO_LAT = 37.4825031;
const MARSAUTO_LON = 127.0426032;

const TMAP_FIT_INFO = {
  large: {
    zoom: 7,
    center: { lat: 35.933540642493455, lng: 127.63916015625041 },
  },
  medium: {
    zoom: 7,
    center: { lat: 35.960222969297014, lng: 127.74117606026829 },
  },
  small: {
    zoom: 7,
    center: { lat: 35.960222969297014, lng: 127.74117606026829 },
  },
};
const TMAP_BOUND = {};


export const getRotation = (lat1, lon1, lat2, lon2) => {
  const dy = lat1 - lat2;
  const dx = lon1 - lon2;

  // Calculate the angle in radians using arctangent
  const angleRad = Math.atan2(dx, dy);

  // Convert radians to degrees
  const angleDeg = angleRad * (180 / Math.PI) - 125

  // Ensure the angle is positive
  const positiveAngle = angleDeg >= 0 ? angleDeg : angleDeg + 360;

  // Adjust the angle to match the initial rotation o f the arrow (-50 degrees)
  return positiveAngle.toFixed(0)
};


const outOfBounds = (lat, lon) => {
  // fix between 34-39 and 125 - 130 (lat and lon bounds of Korea on the map)
  const latMin = 33;
  const latMax = 38.65;
  const lonMin = 124.8;
  const lonMax = 129.64;
  if (lat < latMin || lat > latMax || lon < lonMin || lon > lonMax) {
    return true;
  }
  return false;
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const TmapWindow = forwardRef(({ divID, showAutoFitMapBounds, single }, ref) => {
  const [TmapV2, setTMapV2] = useState(null); // This is the TMap class from the Tmap website
  const [tMap, setTMap] = useState(null); // This is the map instance

  const [autoFitMapBounds, setAutoFitMapBounds] = useState(true);
  const [marsboxList, setMarsboxList] = useState({});
  const markersRef = useRef({});

  useImperativeHandle(ref, () => {
    return {
      marsboxData: marsboxList,
      setMarsboxData: setMarsboxList,
      fitTmap2,
    };
  });

  useEffect(() => {
    // we already import tmap.js in index.html
    const { Tmapv2 } = window;
    setTMapV2(Tmapv2);
  }, []);

  useEffect(() => {
    if (TmapV2) {
      // Set initial location for map to load at
      const marsautoLocation = new TmapV2.LatLng(MARSAUTO_LAT, MARSAUTO_LON);
      const map = new TmapV2.Map(divID, {
        center: marsautoLocation, //지도의 중심좌표.
        width: "100%", // 지도의 넓이
        height: "100%", // 지도의 높이
        zoomControl: false,
        scrollwheel: false,
        draggable: false,
      }); //지도 생성 및 객체 리턴

      // add MarsAuto office
      const marsautoMarker = new TmapV2.Marker({
        icon: marsMarker,
        position: marsautoLocation,
        iconSize: new TmapV2.Size(20, 20),
        title: "Mars Auto",
        zIndex: 20,
      });
      marsautoMarker.setMap(map);

      // Set the bounds of South Korea
      fitTmap2({ width: window.innerWidth, height: window.innerHeight }, map);
      setTMap(map);
    }
  }, [TmapV2, divID]);

  useEffect(() => {
    if (tMap) updateMap();
  }, [tMap, marsboxList]);

  const fitTmap2 = (screenSize, obj = tMap) => {
    if (!obj) return;

    let boundInfo = null;
    if (screenSize.width > 990) {
      boundInfo = "large";
    } else if (770 < screenSize.width && screenSize.width <= 990) {
      boundInfo = "medium";
    } else {
      boundInfo = "small";
    }

    if (!(boundInfo in TMAP_BOUND)) {
      const bound = TMAP_FIT_INFO[boundInfo];
      const mapCenter = new TmapV2.LatLng(bound.center.lat, bound.center.lng);
      TMAP_BOUND[boundInfo] = {
        mapCenter: mapCenter,
        zoom: bound.zoom,
      };
    }
    obj.setCenter(TMAP_BOUND[boundInfo].mapCenter);
    obj.setZoom(TMAP_BOUND[boundInfo].zoom);
  };

  const updateMap = async () => {
    if (!marsboxList) return;

    const availMarsboxList = Object.entries(marsboxList)
      .filter(([key, value]) => {
        return value && value.lat && value.lon && !outOfBounds(value.lat, value.lon);
      })
      .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {});

    const currMarkerKeys = new Set(Object.keys(markersRef.current));
    const nextMarkerKeys = new Set(Object.keys(availMarsboxList));

    const toRemove = new Set([...currMarkerKeys].filter((x) => !nextMarkerKeys.has(x)));
    const toAdd = new Set([...nextMarkerKeys].filter((x) => !currMarkerKeys.has(x)));
    const toUpdate = new Set([...currMarkerKeys].filter((x) => nextMarkerKeys.has(x)));

    toAdd.forEach(function (deviceId) {
      const marboxData = marsboxList[deviceId];
      // @ts-expect-error Tmap API has no types
      const newPosition = new TmapV2.LatLng(marboxData.lat, marboxData.lon);
      // @ts-expect-error Tmap API has no types
      const myMarker = new TmapV2.Marker({
        icon: blueMarker,
        position: newPosition,
        title: deviceId,
        zIndex: marboxData.active ? MARKER_ACTIVE_Z_INDEX : MARKER_INACTIVE_Z_INDEX,
        // pre-define offset blueMarker size = (35, 48). offset should be (width/2, height) of the image.
        // Offset cannot be calculated when the image is not loaded (usually happen at the first usage).
        offset: new TmapV2.Point(17.5, 48),
      });

      myMarker.setMap(tMap);
      myMarker.setTitle(marsboxList[deviceId].plate_number || deviceId || "");

      const element = myMarker.getElement();
      element.classList.add(MARKER_ACTIVE_CLASS);
      //if (marboxData.active) element.classList.add(MARKER_ACTIVE_CLASS);
      //else element.classList.add(MARKER_INACTIVE_CLASS);

      markersRef.current[deviceId] = myMarker;
    });

    toUpdate.forEach(function (deviceId) {
      const marboxData = marsboxList[deviceId];
      // @ts-expect-error Tmap API has no types
      const newPosition = new TmapV2.LatLng(marboxData.lat, marboxData.lon);
      const currMarker = markersRef.current[deviceId];
      const element = currMarker.getElement();

      const curRotation = Number(element.style.transform.replace("rotate(", "").replace("deg)", ""))
      const rotation = getRotation(marboxData?.prev_lat || marboxData.lat, marboxData?.prev_lon || marboxData.lon, marboxData.lat, marboxData.lon);
      if (Math.abs(curRotation - Number(rotation)) < 90 || curRotation === 0) {
        element.style.transform = `rotate(${rotation}deg)`;
      }

      currMarker.setPosition(newPosition);

      if (marboxData.active) {
        element.classList.remove(MARKER_INACTIVE_CLASS);
        element.classList.add(MARKER_ACTIVE_CLASS);
      } else {
        element.classList.remove(MARKER_ACTIVE_CLASS);
        element.classList.add(MARKER_INACTIVE_CLASS);
      }

      if (currMarker.getElement().classList.contains(MARKER_HIGHLIGHT_CLASS)) {
        element.style.zIndex = MARKER_HIGHLIGHT_Z_INDEX;
      } else if (marboxData.active) {
        element.style.zIndex = MARKER_ACTIVE_Z_INDEX;
      } else {
        element.style.zIndex = MARKER_INACTIVE_Z_INDEX;
      }

      element.classList.add(MARKER_ACTIVE_CLASS);
      element.style.zIndex = MARKER_ACTIVE_Z_INDEX;
    });

    toRemove.forEach(function (deviceId) {
      const marker = markersRef.current[deviceId];
      marker.setMap(null);
      delete markersRef.current[deviceId];
    });

    //console.log("tmap", TmapV2);
    //console.log("tmap getBounds", tMap.getBounds());
    //console.log("tmap getZoom", tMap.getZoom());

    //if (autoFitMapBounds && !isEmptyObject(markersRef.current)) {
    //  fitTmap();
    //}
  };

  return (
    <>
      <div
        className={`${styles[single ? "single-map-container" : "map-container"]
          } tmap-window-wrapper`}
      >
        {showAutoFitMapBounds && (
          <div className={styles["map-checkbox-container"]}>
            <div
              label="지도 자동 조정"
              control={
                <input
                  type="checkbox"
                  checked={autoFitMapBounds}
                  onChange={(e) => setAutoFitMapBounds(e.target.checked)}
                />
              }
              className={styles["map-checkbox"]}
            />
          </div>
        )}
        <div id={divID} className={"tmap"} />
      </div>
    </>
  );
});

export default TmapWindow;
