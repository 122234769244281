import { LeftParagraphWithPhoto, RightParagraphWithPhoto } from './paragraphWithPhoto';
import { useTranslation } from 'react-i18next';

import img1 from './static/img/img_16.webp';
import img2 from './static/img/img_15.webp';
import img3 from './static/img/img_20.webp';
import img4 from './static/img/img_17.webp';
import img5 from './static/img/img_19.webp';
import img6 from './static/img/img_21.webp';
import truck from './static/driving.gif';
import truck2 from './static/truck2.webp';


function About(props) {
    const { t } = useTranslation();
    return (
        <div class="container">
            <div class="row">
                <div class="col-md-12 mx-auto text-center">
                    <h2 class="heading-black">{t('about_title1')}</h2>
                </div>
            </div>
            <div id="working-photo-list" class="d-flex flex-wrap justify-content-center">
                <img src={img1} class="photo rounded" alt="Work 1" />
                <img src={img2} class="photo rounded" alt="Work 2" />
                <img src={img3} class="photo rounded" alt="Work 3" />
                <img src={img4} class="photo rounded" alt="Work 4" />
                <img src={img5} class="photo rounded" alt="Work 5" />
                <img src={img6} class="photo rounded" alt="Work 6" />
            </div>
            <div class="row mt-5 mt-lg-6">
                <div class="col-10 col-md-10 col-lg-7 mx-auto">
                    <h2 class="heading-black text-center">{t('about_title2')}</h2>
                    <p class="lead text-left">
                        {t('about_text1')}
                    </p>
                    <p class="lead text-left">
                        {t('about_text2')}
                    </p>
                </div>
                <RightParagraphWithPhoto
                    photo={truck}
                    title={t('about_title3')}
                    text={t('about_text3')}
                    button={t('about_button1')}
                    dst="/technology"
                    showButton={true}
                    left="col-10 col-lg-7 order-2 order-lg-1"
                    right="col-10 col-lg-5 order-1 order-lg-2 text-center"
                />
                <LeftParagraphWithPhoto
                    photo={truck2}
                    title={t('about_title4')}
                    text={t('about_text4')}
                    dst="/business"
                    left="col-10 col-lg-5"
                    right="col-10 col-lg-7"
                />
            </div>
        </div >
    );
}

export default About;
