import Menu from './menu';
import Footer from './footer';
import PressDate from './pressDate';
import ScrollToTop from './utils';

import './press.css';

import logo from './static/logo_white.webp';
import truck from './static/truck02.webp';

function Press(props) {
    return (
        <div>
            <ScrollToTop />
            <Menu />
            <section class="container py-6">
                <h2 class="col-md-6 mx-auto text-center text-white heading-black">IN THE NEWS</h2>
                <h3 class="col-md-8 mx-auto text-center text-white heading-black">
                    Mars Auto is building autonomous trucks to fully automate long-haul
                    truck operations. <br />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.
                    Donec scelerisque sollicitudin enim eu venenatis. Duis tincidunt laoreet ex, in pretium orci vestibulum eget.
                </h3>
                <div class="pb-5">
                    <div class="d-flex justify-content-center">
                        <img src={truck} class="img-fluid col-12" />
                    </div>
                </div>
                <div class="row">
                    <h3 class="text-white col-2 no-bottom-margin">Date</h3>
                    <h3 class="text-white col-2 no-bottom-margin">Source</h3>
                    <h3 class="text-white col-8 no-bottom-margin">Headline</h3>
                </div>
                <hr class="no-top-margin" />
                <div class="row mx-auto">
                    <PressDate
                        month="Sep" day="08"
                        source={logo}
                        headline="자율트럭, '고비용'운송시장 변화 이끌죠"
                        link="https://www.sedaily.com/NewsView/22RDUOUMXI"
                    />
                    <PressDate
                        month="June" day="29"
                        source={logo}
                        headline="자율주행 트럭 경쟁 '활활'"
                        link="https://paxnetnews.com/articles/75642"
                    />
                    <PressDate
                        month="May" day="08"
                        source={logo}
                        headline="[포브스 아시아 30세 이하 리더] 포브스가 주목한 20대 CEO"
                        link="http://jmagazine.joins.com/forbes/view/334108"
                    />
                    <PressDate
                        month="Dec" day="17"
                        source={logo}
                        headline="박사 하나 없이 일냈다…'100만원 장비'로 가는 자율트럭"
                        link="https://www.joongang.co.kr/article/23947584#home"
                    />
                    <PressDate
                        month="Dec" day="16"
                        source={logo}
                        headline="파주~대전 500㎞ 자율주행트럭, 박사 한명 없이 만든 韓벤처"
                        link="https://www.joongang.co.kr/article/23946642"
                    />
                    <PressDate
                        month="Dec" day="06"
                        source={logo}
                        headline="자율주행 스타트업 마스오토, 파주-대전간 자율주행 화물운송 실현해"
                        link="https://www.clomag.co.kr/article/3521"
                    />
                    <PressDate
                        month="Dec" day="04"
                        source={logo}
                        headline="Mars Auto: Creating Autonomy Driving Technology without LiDAR and HD Map"
                        link="https://pickool.net/mars-auto-creating-autonomy-driving-technology-without-lidar-and-hd-map/"
                    />
                    <PressDate
                        month="Sep" day="23"
                        source={logo}
                        headline="[자율주행 분야의 딥테크 강자들] 박일수 마스오토 대표"
                        link="http://jmagazine.joins.com/forbes/view/331457"
                    />
                    <PressDate
                        month="June" day="05"
                        source={logo}
                        headline="자율주행 트럭 경쟁 '활활'[Startup人터뷰]화물운송 자율주행 소프트웨어를 개발하는 '마스오토'의 박일수 대표"
                        link="https://startup.kaist.ac.kr/startup%e4%ba%ba%ed%84%b0%eb%b7%b0-%ed%99%94%eb%ac%bc%ec%9a%b4%ec%86%a1-%ec%9e%90%ec%9c%a8%ec%a3%bc%ed%96%89-%ec%86%8c%ed%94%84%ed%8a%b8%ec%9b%a8%ec%96%b4%eb%a5%bc-%ea%b0%9c%eb%b0%9c%ed%95%98/"
                    />
                    <PressDate
                        month="Nov" day="05"
                        source={logo}
                        headline="국내 최초 화물차 무인운송 꿈꾼다, 'MARS AUTO'"
                        link="https://www.klnews.co.kr/news/articleView.html?idxno=120280"
                    />
                    <PressDate
                        month="Aug" day="20"
                        source={logo}
                        headline="All 84 startups from Y Combinator’s S19 Demo Day 1"
                        link="https://techcrunch.com/2019/08/19/all-84-startups-from-y-combinators-s19-demo-day-1/"
                    />
                    <PressDate
                        month="Mar" day="07"
                        source={logo}
                        headline="로지스퀘어–마스오토, 자율주행 트럭 이용한 H2H 간선운송 서비스 공동개발 협약 체결"
                        link="https://clomag.co.kr/article/3229"
                    />
                    <PressDate
                        month="Dec" day="04"
                        source={logo}
                        headline="'마스오토', 케이큐브벤처스와 카카오모빌리티로부터 4억 원 투자유치"
                        link="https://platum.kr/archives/92162"
                    />
                </div>
            </section>
            <Footer />
        </div >
    );
}

export default Press;
