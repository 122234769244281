import Menu from './menu';
import Footer from './footer';
import ScrollToTop from './utils';
import { Articles } from './articles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

import './press.css';

// import img1 from './static/press/photos01.webp';
// import img2 from './static/press/photos02.webp';
// import img3 from './static/press/photos03.webp';
// import img4 from './static/press/photos04.webp';
// import img5 from './static/press/photos05.webp';
// import img6 from './static/press/photos06.webp';
// import img7 from './static/press/photos07.webp';

// import press01 from './static/press/press01.webp';
import press02 from './static/press/press02.webp';
import press03 from './static/press/press03.webp';
import press04 from './static/press/press04.webp';
import press05 from './static/press/press05.webp';
import press06 from './static/press/press06.webp';
import press07 from './static/press/press07.webp';
import press08 from './static/press/press08.webp';
import press09 from './static/press/press09.webp';
import press10 from './static/press/press10.jpeg';
import press11 from './static/press/press11.webp';
import press12 from './static/press/press12.webp';
import press13 from './static/press/press13.webp';
import press14 from './static/press/press14.webp';
import press15 from './static/press/press15.webp';
import press16 from './static/press/press16.webp';
import press17 from './static/press/press17.webp';
import press18 from './static/press/press18.webp';
import press19 from './static/press/press19.webp';
import press20 from './static/press/press20.webp';
import press21 from './static/press/press21.png';
import press22 from './static/press/press22.png';
import press23 from './static/press/press23.png';
import press24 from './static/press/press24.png';
import press25 from './static/press/press25.png';
import press26 from './static/press/press26.png';
import press27 from './static/press/press27.png';
import press28 from './static/press/press28.jpg';
import press29 from './static/press/press29.jpg';
import press30 from './static/press/press30.jpg';
import press31 from './static/press/press31.jpeg';
import press32 from './static/press/press32.png';
import press33 from './static/press/press33.jpeg';
import press34 from './static/press/press34.jpeg';
import press35 from './static/press/press35.png';


function Press2(props) {
    return (
        <div>
            <ScrollToTop />
            <Menu />

            <Articles
                title="VIEW LATEST ARTICLES"
                column={true}
                content={[
                    {
                        title: "노제경 마스오토 COO, 미들마일 물류 자동화 이끌겠다",
                        text: '노제경 마스오토 최고운영책임자(COO·사진)는 6일 한국경제신문과의 인터뷰에서 “미들마일 물류 시장을 자동화하는 게 목표”라고 말했다. 국내 미들마일 시장 규모는 37조원 이상이다.',
                        img: press35,
                        link: "https://www.hankyung.com/article/2024080658061",
                        footer: "Aug 6, 2024",
                    },
                    {
                        title: "Mars Auto Announces U.S. Operations Relocation to Round Rock, Texas",
                        text: 'Mars Auto, a leading innovator in autonomous vehicle technology based in South Korea, is thrilled to announce the relocation of its U.S. operations from the Dallas area into Round Rock, Texas.',
                        img: press34,
                        link: "https://roundrockchamber.org/mars-auto-announces-u-s-operations-relocation-to-round-rock-texas/",
                        footer: "Jul 31, 2024",
                    },
                    {
                        title: "한국도로공사-마스오토와 고속도로를 활용한 자율주행 서비스 확대를 위한 업무협약 체결",
                        text: '한국도로공사(사장 함진규)는 마스오토(대표 박일수)와 지난 10일 판교 EX스마트센터에서 고속도로를 활용한 자율주행 서비스 확대를 위한 업무협약을 체결했다고 11일 밝혔다.',
                        img: press33,
                        link: "https://www.munhwa.com/news/view.html?no=2024071101039905015010",
                        footer: "Jul 11, 2024",
                    },
                    {
                        title: "CJ대한통운, 택배상품 간선운송에 자율주행 시범 도입",
                        text: 'CJ대한통운은 자율주행 스타트업 마스오토와 손잡고 자율주행 트럭을 활용한 택배 간선차량 운행 시범사업을 시작한다고 14일 밝혔다. 이에 3월 말부터 약 1년간 주 6회 실제 택배상품을 실은 11톤 대형트럭이 CJ대한통운 인천장치장센터에서 옥천허브터미널까지 218km의 간선노선을 운행한다.',
                        img: press32,
                        link: "https://www.mbn.co.kr/news/economy/5010890",
                        footer: "Mar 14, 2024",
                    },
                    {
                        title: "롯데글로벌로지스, '자율주행 로봇 배송 실증' 확대 추진",
                        text: '롯데글로벌로지스는 현재 자율주행 트럭 스타트업 마스오토(Mars Auto)와 자율주행 화물차 2차 실증사업도 진행하고 있다. 롯데글로벌로지스 관계자는 "자율주행 화물차 1차 실증사업을 통해 자율주행기술 안정성을 확인했다"며 "연비도 평균 11.8% 개선됐다"고 전했다.',
                        img: press31,
                        link: "https://www.news1.kr/articles/5344425",
                        footer: "Mar 08, 2024",
                    },
                    {
                        title: "마스오토, ‘대형 트레일러’로 자율주행 실증 확대",
                        text: '마스오토는 국토교통부 모빌리티 혁신위원회에서 대형 트레일러 자율주행 실증특례를 확보했다고 밝혔다. 트랙터와 트레일러를 분리할 수 있는 연결 자동차에 자율주행 기술을 적용하면 폭넓은 비즈니스 모델을 구현할 수 있을 것으로 기대된다.',
                        img: press30,
                        link: "https://www.klnews.co.kr/news/articleView.html?idxno=311242",
                        footer: "Feb 19, 2024",
                    },
                    {
                        title: "Self-driving truck startup Mars Auto eyes 'middle-mile' market in U.S.",
                        text: 'South Korean self-driving truck startup Mars Auto is taking aim at making inroads into the "middle-mile" market in the United States as it seeks to bank on the business-to-business segment of autonomous delivery in America.',
                        img: press29,
                        link: "https://en.yna.co.kr/view/AEN20231215005700320?input=2106m",
                        footer: "Dec 15, 2023",
                    },
                    {
                        title: "SKT-마스오토, 대형트럭 자율주행 고도화 협력",
                        text: '데이터 수집장치인 마스박스를 통해 2024년까지 누적 1억km 주행 데이터를 실시간 통신으로 주고 받으며 학습시키고, 파트너사를 위한 관제 솔루션까지 제공할 계획이다.',
                        img: press28,
                        link: "https://biz.chosun.com/it-science/ict/2023/10/23/GMNNGI5J5ZEZLIW6K3FI4BA364",
                        footer: "Oct 24, 2023",
                    },
                    {
                        title: "8.5톤 윙보디 트럭 손발 떼도 고속도로 안전 주행",
                        text: '최전방부터 후방까지 전국 곳곳에 부대가 있는 만큼 군수품 보급을 맡은 이의 임무 수행 능력은 매우 중요하다. 하지만 이 과정에서 체력적 과부하가 걸릴 수도 있는 일. 하지만 자율주행 트럭이 도입되면 운전자의 체력적·정신적 업무 부담을 덜어 원활한 작전에 도움을 줄 전망이다.',
                        img: press27,
                        link: "https://kookbang.dema.mil.kr/newsWeb/20230919/5/ATCE_CTGR_0010030000/view.do",
                        footer: "Sep 18, 2023",
                    },
                    {
                        title: "마스오토, 육군과 자율주행 트럭 운송 서비스 MOU 체결",
                        text: '육군은 올해 8월부터 마스오토 자율주행 트럭을 활용해 여러 물류센터로 군수품을 운송하게 된다. 박일수 마스오토 대표는 "대형트럭 자율주행은 평시 수송작전을 포함한 군수분야에서 폭넓게 활용될 수 있는 기술"이라며 이번 MOU 배경을 설명했다.',
                        img: press26,
                        link: "https://news.mt.co.kr/mtview.php?no=2023070410233245408",
                        footer: "Jul 04, 2023",
                    },
                    {
                        title: "70만㎞ 달린 자율주행 K-트럭 '마스오토', 美 텍사스 누빈다",
                        text: "현재 글로벌 시장에서 자율주행 트럭 솔루션을 제공하는 업체는 10여개다. 이중 국내 업체는 마스오토가 유일하다. 현재 7대 자율주행 트럭을 운행하고 있으며 월 10만㎞, 누적 70만㎞의 자율주행 실적을 쌓았다. ",
                        img: press25,
                        link: "https://news.mt.co.kr/mtview.php?no=2023062911022289569",
                        footer: "Jun 29, 2023",
                    },
                    {
                        title: "트럭 운전도 곧 AI가 모방···원가 40% 절감 기대",
                        text: "물류회사의 마진이 높아야 5%인 점을 감안하면 1마일당 원가 40% 절감은 굉장히 파괴적인 수치다.",
                        img: press24,
                        link: "http://www.sisajournal-e.com/news/articleView.html?idxno=298385",
                        footer: "Mar 30, 2023",
                    },
                    {
                        title: "인천에서 부산까지 자율주행 화물 운송...'국내 최초'",
                        text: "소프트웨어를 실행하면 인공지능이 스스로 차량을 제어해 운전자가 핸들에서 완전히 손을 뗄 수 있는 레벨3 수준의 자율주행으로 운행됩니다.",
                        img: press23,
                        link: "https://www.ytn.co.kr/_ln/0102_202303180605139537",
                        footer: "Mar 08, 2023",
                    },
                    {
                        title: '자율주행 트럭 첫 상용화, 세계 1위 도전 스타트업들',
                        text: "AI(인공지능) 전공 20대 청년 두 명이 1000만원짜리 중고 1t 트럭 한 대로 시작한 회사는 올해 1분기 중 대형 트럭 14대로 인천~부산 간 자율주행 화물 운송 서비스를 시작한다.",
                        img: press22,
                        link: "https://www.chosun.com/economy/tech_it/2023/01/10/C3LJA5UYUFFGZGP64NAPVC5KLQ",
                        footer: "Jan 10, 2023",
                    },
                    {
                        title: '카카오 투자받은 마스오토 CES 혁신상',
                        text: "카카오벤처스는 자사가 투자한 마스오토와 레티널, 스마트레이더시스템이 CES(세계가전전시회)에서 혁신상을 받았다고 밝혔다.",
                        img: press21,
                        link: "https://www.yna.co.kr/view/AKR20221207052800017?input=1195m",
                        footer: "Dec 07, 2022",
                    },
                    {
                        title: '티맵모빌리티-마스오토 "자율주행 화물차 상용화 앞당긴다"',
                        text: "티맵모빌리티가 자율주행 솔루션 개발 스타트업 마스오토와 자율주행 화물·여객운송 준비에 나선다. 티맵모빌리티는 마스오토와 '자율주행 플릿(fleet) 서비스 협력을 위한 양해각서(MOU)'를 체결했다고 21일 밝혔다.",
                        img: press20,
                        link: "https://n.news.naver.com/mnews/article/008/0004761053?sid=105",
                        footer: "Jun 21, 2022",
                    },
                    {
                        title: "자율주행트럭 내년 상용화 추진…하반기에 10대로 시범운행 이 회사는",
                        text: '"세계적으로도 트럭 자율주행 기술은 흔치 않은 만큼, 한국을 넘어 미국 시장까지 공략할 계획입니다." 마스오토는 국내에서 유일하게 트럭 자율주행 기술을 개발하는 인공지능(AI) 스타트업이다.',
                        img: press18,
                        link: "https://n.news.naver.com/mnews/article/009/0004957721?sid=105",
                        footer: "May 01, 2022",
                    },
                    {
                        title: "마스오토-로지스퀘어, 하반기 '수도권-부산' 자율주행 트럭 시범운행",
                        text: "자율주행 트럭 스타트업 마스오토는 기업화물 전문 운송사 로지스퀘어와 트럭 자율주행 기술 기반의 국내 간선운송 물류사업 수행을 위한 조인트벤처(JV)를 설립한다고 12일 밝혔다.",
                        img: press16,
                        link: "https://www.etnews.com/20220412000021",
                        footer: "Apr 12, 2022",
                    },
                    {
                        title: "자율주행으로 450㎞ 달리는 ‘마스오토’, 트럭계 테슬라 꿈꾼다",
                        text: "국내 유일 자율주행 트럭 스타트업 ‘마스오토‘가 본격적인 화물 운송 사업에 돌입한다. 연내 자체 개발한 유인 자율주행 트럭으로 직접 운송 서비스를 선보일 예정이다.",
                        img: press17,
                        link: "http://www.sisajournal-e.com/news/articleView.html?idxno=265461",
                        footer: "Apr 06, 2022",
                    },
                    {
                        title: "자율주행 트럭 기업 마스오토, 150억원 규모 투자 유치",
                        text: "자율주행 트럭 초기 기업인 마스오토(Mars Auto)가 약 150억원 규모 시리즈A 투자를 유치했다고 31일 밝혔다. 마스오토는 화물 운송용 트럭을 위한 인공지능(AI) 기반 자율주행...",
                        img: press14,
                        link: "https://www.mk.co.kr/news/it/view/2022/03/291295/",
                        footer: "Mar 31, 2022",
                    },
                    {
                        title: "자율주행 트럭, '고비용'운송시장 변화 이끌죠",
                        text: "자율주행차 기술은 기술 자체로 그치지 않고 사업적 가치를 만들어내야 합니다. 고정밀 지도와 위치정보시스템(GPS)에 의존하지 않고도 카메라로 자율주행하는 기술로 국내 운송 서비스...",
                        img: press13,
                        link: "https://www.sedaily.com/NewsView/22RDUOUMXI",
                        footer: "Sep 08, 2021",
                    },
                    {
                        title: "자율주행 트럭 경쟁 '활활'",
                        text: "지난 4월 20일 '자율주행 전쟁 1차전' 보고서를 낸 삼성증권은 '대형 트럭을 활용한 물류시장은 시장 규모, 수요자, 공급자, 정책 당국 관점에서 이해관계자 간의 큰 충돌 없이 자율주행...",
                        img: press12,
                        link: "https://paxnetnews.com/articles/75642",
                        footer: "Jun 29, 2021",
                    },
                    {
                        title: "[포브스 아시아 30세 이하 리더] 포브스가 주목한 20대 CEO",
                        text: "마스오토는 박일수 대표가 2017년 창업한 자율주행 트럭 개발 스타트업이다. 대부분의 자율주행 기술처럼 고화질 지도와 고정밀 GPS에 의존하지 않고 트럭에 설치된 카메라로 주행 데이...",
                        img: press11,
                        link: "http://jmagazine.joins.com/forbes/view/334108",
                        footer: "May 08, 2021",
                    },
                    {
                        title: "박사 하나 없이 일냈다…'100만원 장비'로 가는 자율트럭",
                        text: "자율주행차 기술은 기술 자체로 그치지 않고 사업적 가치를 만들어내야 합니다. 고정밀 지도와 위치정보시스템(GPS)에 의존하지 않고도 카메라로 자율주행하는 기술로 국내 운송 서비스...",
                        img: press10,
                        link: "https://www.joongang.co.kr/article/23947584#home",
                        footer: "Dec 17, 2020",
                    },
                    {
                        title: "파주~대전 500㎞ 자율주행트럭, 박사 한명 없이 만든 韓벤처",
                        text: "자율주행 스타트업 마스오토는 지난달부터 경기도 파주와 대전 사이를 오가는 자율주행 트럭을 운행하고 있다. 물류센터의 택배 상자를 옮기는 간선운송 업무에 자율주행 트럭이 투입된 것...",
                        img: press09,
                        link: "https://www.joongang.co.kr/article/23946642",
                        footer: "Dec 16, 2020",
                    },
                    {
                        title: "자율주행 스타트업 마스오토, 파주-대전간 자율주행 화물운송 실현해",
                        text: "자율주행 기술 스타트업 ‘마스오토(Mars Auto)’가 자사 자율주행 기술을 이용해 화물 운송에 나선다. 기존 공차나 팔레트를 기반으로 한 테스트 주행이 아닌 실제 가치를 지닌 화물을...",
                        img: press08,
                        link: "https://www.clomag.co.kr/article/3521",
                        footer: "Dec 06, 2020",
                    },
                    {
                        title: "Mars Auto: Creating Autonomy Driving Technology without LiDAR and HD Map",
                        text: 'Have you ever imagined seeing the real case from a drama or movie? The South Korean Korean TV Series "Startup" shares the story from its tech-based...',
                        img: press07,
                        link: "https://pickool.net/mars-auto-creating-autonomy-driving-technology-without-lidar-and-hd-map/",
                        footer: "Dec 04, 2020",
                    },
                    {
                        title: "[자율주행 분야의 딥테크 강자들] 박일수 마스오토 대표",
                        text: "미국 자율주행 분야도 코로나19를 피하진 못했다. 미국 GM의 자율주행차 자회사인 크루즈가 전체 직원 1800명 중 8%인 140명을 줄였다. 포드는 2021년 선보이려던 자율주행차 출시...",
                        img: press06,
                        link: "http://jmagazine.joins.com/forbes/view/331457",
                        footer: "Sep 23, 2020",
                    },
                    {
                        title: "자율주행 트럭 경쟁 '활활'[Startup人터뷰]화물운송 자율주행 소프트웨어를 개발하는 '마스오토'의 박일수 대표",
                        text: "마스오토에 대한 간단한 소개 부탁드립니다. 마스오토는 화물운송 트럭을 위한 자율주행 소프트웨어를 만들고 있으며 국내 최초 소형 트럭으로 2018년 국토부에서 자율주행 임시운행 허가...",
                        img: press05,
                        link: "https://startup.kaist.ac.kr/startup%e4%ba%ba%ed%84%b0%eb%b7%b0-%ed%99%94%eb%ac%bc%ec%9a%b4%ec%86%a1-%ec%9e%90%ec%9c%a8%ec%a3%bc%ed%96%89-%ec%86%8c%ed%94%84%ed%8a%b8%ec%9b%a8%ec%96%b4%eb%a5%bc-%ea%b0%9c%eb%b0%9c%ed%95%98/",
                        footer: "Jun 05, 2020",
                    },
                    {
                        title: "국내 최초 화물차 무인운송 꿈꾼다, 'MARS AUTO'",
                        text: "4차 산업혁명 시대가 도래함에 따라 인공지능 기술이 각 산업 곳곳에 스며들고 있다. 이는 더 빠르고 더 안전하고 정확한 업무를 추구하는 물류업계에서도 당연히 마찬가지이다. 실제 근 몇 ...",
                        img: press04,
                        link: "https://www.klnews.co.kr/news/articleView.html?idxno=120280",
                        footer: "Nov 05, 2019",
                    },
                    {
                        title: "All 84 startups from Y Combinator’s S19 Demo Day 1",
                        text: "It’s that time of year, Silicon Valley’s investor technocrati and advice-giving Twitter celebrities descended upon Pier 48 in San Francisco to judge the late...",
                        img: press03,
                        link: "https://techcrunch.com/2019/08/19/all-84-startups-from-y-combinators-s19-demo-day-1/",
                        footer: "Aug 20, 2019",
                    },
                    {
                        title: "로지스퀘어–마스오토, 자율주행 트럭 이용한 H2H 간선운송 서비스 공동개발 협약 체결",
                        text: "기업화물 전문 운송사 ‘로지스퀘어’와 화물운송 자율주행 기술 스타트업 ‘마스오토’가 자율주행 트럭을 이용한 허브 투 허브(Hub to Hub) 장거리 간선운송 서비스 공동 개발을 위한 협약을...",
                        img: press02,
                        link: "https://clomag.co.kr/article/3229",
                        footer: "Mar 07, 2019",
                    },
                    {
                        title: "'마스오토', 케이큐브벤처스와 카카오모빌리티로부터 4억 원 투자유치",
                        text: "AI 기반 자율주행 트럭용 소프트웨어를 개발하는 ‘마스오토'(대표 박일수)가 케이큐브벤처스와 카카오모빌리티로부터 4억 원을 투자 유치했다고 4일 밝혔다. 이번 투자는 케이큐브벤처스와 카...",
                        img: press04,
                        link: "https://platum.kr/archives/92162",
                        footer: "Dec 04, 2017",
                    },
                ]}
            />
            <Footer />
        </div >
    );
}

export default Press2;
