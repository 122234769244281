function Article(props) {
    return (
        <div class="col-10 col-lg-4 mb-4">
            <div class="card h-100" >
                <img class="card-img-top" src={props.img} alt="Card image cap" />
                <div class="card-body">
                    <h5 class="card-title">{props.title}</h5>
                    <p class="card-text">{props.text}</p>
                </div>
                <div class="card-footer">
                    <small class="">{props.footer}</small>
                </div>
                <a target="_blank" href={props.link} class="stretched-link"></a>
            </div>
        </div>
    )
}

function Articles(props) {
    var cards;
    if (props.column) {
        cards = <div class="row d-flex flex-wrap justify-content-center">
            {props.content.map(x =>
                <Article title={x.title} text={x.text} img={x.img} footer={x.footer} link={x.link} />)
            }
        </div>;
    } else {
        cards =
            <div class="row d-flex flex-wrap justify-content-center">
                {props.content.map(x =>
                    <Article title={x.title} text={x.text} img={x.img} footer={x.footer} link={x.link} />)
                }
            </div>;
    }

    return (
        < div class="pt-5 pt-lg-7 pb-6" >
            <div class="container">
                {/* <h2 class="heading-black mb-0">
                    {props.title}
                </h2>
                <hr class="no-top-margin" /> */}
                {cards}
            </div>
        </div>
    );
}

export { Article, Articles };
